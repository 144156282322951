import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
import localStorage from '@/utils/userToken';
const routes = [
    {
      path: '/',        //默认首页
      redirect:'/home',
      meta: {
        title: '普视云'
      },
    }, 
    {
      path: '/home',  //首页
      name: 'Home',
      meta: {
        title: '普视云'
      },
      component: () => import('@/views/Home'),
    }, 
    {
      path: '/database', //微众数据库
      name: 'Database',
      redirect:'/database/caselist',
      meta: {
        title: '微众数据库'
      },
      component: () => import('@/views/Database'),
      children: [
        {
          path: 'newcase',// 新建病例
          name: 'NewCase',
          component: () => import('@/views/Database/NewCases')
        },
        {
          path: 'caselist',// 病例列表
          name: 'CaseList',
          component: () => import('@/views/Database/CaseList')
        },
        {
          path: 'caseoverview',// 病例概览
          name: 'CaseOverview',
          component: () => import('@/views/Database/CaseOverview')
        },
        {
          path: 'certification',// 认证审核
          name: 'Certification',
          component: () => import('@/views/Database/certification')
        }
      ]
  }, 
  {
    path: '/reconstruction', //三维重建
    name: 'Reconstruction',
    meta: {
      title: '三维重建'
    },
    component: () => import('@/views/Reconstruction'),
  }, 
    {
      path: '/share', //病例分享
      name: 'Share',
      meta: {
        title: '病例分享'
      },
      component: () => import('@/views/Database/CaseSharing'),
    }, 
    {
      path: '/lookshare/:u/:db', //查看分享
      name: 'LookShare',
      meta: {
        title: '查看分享'
      },
      component: () => import('@/views/Database/LookSharing'),
    },
    {
      path: '/lookshared/:u/:db', //查看分享
      name: 'LookShared',
      meta: {
        title: '分享详情'
      },
      component: () => import('@/views/Database/LookSharing/showCase.vue'),
    },
    {
      path: '/casedetails', //病例详情
      name: 'CaseDetails',
      meta: {
        title: '病例详情'
      },
      component: () => import('@/views/Database/CaseDetails'),
    }, 
    {
      path: '/caserelease', //病例发布
      name: 'CaseRelease',
      meta: {
        title: '病例发布'
      },
      component: () => import('@/views/Database/CaseRelease'),
    },
    {
      path: '/caserelease/publicupdate', //病例发布编辑
      name: 'PublicUpdate',
      meta: {
        title: '病例发布'
      },
      component: () => import('@/views/Database/CaseRelease/publicUpdate.vue'),
    },
    {
      path: '/pc', //个人中心
      name: 'pc',
      meta: {
        title: '个人中心'
      },
      redirect:'/pc/pershomepage',
      component:() => import('@/views/PersonalCenter'),
      children:[
        {
          path:'pershomepage',
          name: 'PersHomepage',
          meta: {
            title: '个人中心'
          },
          component:() => import('@/views/PersonalCenter/persHomepage.vue')
        },
        {
          path:'mesnoticy',
          name: 'MessageNoticy',
          meta: {
            title: '消息通知'
          },
          component:() => import('@/views/PersonalCenter/messageNoticy.vue')
        },
        {
          path:'notice/:activeName',    //用户公告模块
          name: 'Notice',
          meta: {
            title: '用户公告'
          },
          component: () => import('@/views/PersonalCenter/notice.vue'),
        },
        {
          path:'caseoperate',    //病例操作
          name: 'CaseOperate',
          meta: {
            title: '病例操作'
          },
          component: () => import('@/views/PersonalCenter/caseOperate.vue'),
        },
        {
          path:'accountinfo',    //账户资料
          name: 'AccountInformation',
          meta: {
            title: '账户资料'
          },
          component: () => import('@/views/PersonalCenter/accountInformation.vue'),
        },
        //新需求
        // 用户通知/用户私信/官方公告 Information notification
        {
          path:'accountinfonotice',    //账户信息通知
          name: 'AccountInformationNotice',
          meta: {
            title: '账户信息通知'
          },
          component: () => import('@/views/PersonalCenter/new/accountinfonotice.vue'),
        },
      ]
    },
    //新的病例操作 --2023-8-29
    {
      path: '/caserecord', //已发布病例
      name: 'CaseRecord',
      meta: {
        title: '已发布病例'
      },
      component:() => import('@/views/CaseOperate/index.vue'),
    },
    {
      path: '/account',   //账号安全
      name: 'AccountSecurity',
      meta: {
        title: '账号安全'
      },
      component: () => import('@/views/AccountSecurity'),
    },
    {
      path: '/case',    //所有病例
      name: 'Case',
      meta: {
        title: '所有病例'
      },
      component: () => import('@/views/Case'),
    },
    {
      path: '/search',    //搜索病例
      name: 'Search',
      meta: {
        title: '搜索病例'
      },
      // redirect:'/search/case',
      component: () => import('@/views/Search'),
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/test',            //测试
      name: 'test',
      meta: {
        title: '测试'
      },
      component: () => import('@/views/Test'),
  },
  {
    path: '/persp3d/disclaimer',    //app免责声明
    name: 'Disclaimer',
    meta: {
      title: '免责声明'
    },
    component: () => import('@/views/Persp3d/disclaimer'),
  },
  {
    path: '/persp3d/privacyagreement',    //app隐私协议
    name: 'Privacyagreement',
    meta: {
      title: '隐私协议'
    },
    component: () => import('@/views/Persp3d/privacyagreement'),
  },
  {
    path: '/persp3d/arcode',    //app隐私协议
    name: 'ARCode',
    meta: {
      title: '隐私协议'
    },
    component: () => import('@/views/Persp3d/ARCode'),
  },
]

const router = new VueRouter({
    mode:'history',
    // mode:'hash'  // 有#
    scrollBehavior: () => ({ y: 0 }),
    routes,
})

router.beforeEach((to, from, next) => {
  // const whiteList = ['/', '/home','/case','/search']
  // const token=localStorage.getToken()&&localStorage.getToken().token
  // if (!token &&!whiteList.includes(to.path)) {
  //   Vue.prototype.$message.error('无权限,请先登录!')
  // } else {
  //   next()
  // }
  if(to.fullPath =='/account'){
    document.querySelector('body').setAttribute('style','background-color:#F6F8FA')  
  }
  document.title = '普视云'
  next();
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err => err);
}
router.afterEach((to,from,next) => {
    // console.log('后置');
    setTimeout(()=>{
      //百度统计
      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?4c81a5c6f3f0d22d6c4fb0b0fccd7ea0";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      })();
    },0); 
})

export default router;
 